html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

p {
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}

.caseSummary {
  width: 100%;
  background-color: transparent;
  padding: 1rem;

}

.split {
  width: 100%;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}

.wrapper {
  width: 100%;
  display: grid;
  gap: 1rem;
}

.name {
  text-transform: capitalize;
}

.container {
  display: flex;
  width: 100%;
  border: 1px solid #B8C4E0;
  border-radius: 8px;
}

.info-box {
  display: grid;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  padding: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  background-color:  #F1F3F9;
  color:#6E7686;
}

.content-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
  background-color: transparent;
  padding: 8px 6px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.phone-link,
.email-link,
.data-display {
  display: flex;
  align-items: center;
  color: #1B4DF5;
  font-size: 14px;
  :hover {
    cursor: pointer;
  }
}

.even {
  justify-content: flex-start;
  text-align: left;
}

.odd {
  justify-content: flex-end;
  text-align: right;
}

.email-link {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.data-display {
  word-break: break-word;
  color: #1E1E1E;
  font-weight: 500;
  font-size: 14px;
}

.national-id {
  color: #6E7686;
  font-size: 12px;
}

.summary {
  display: grid;
  width: 100%;
  border: 1px solid #B8C4E0;
  border-radius: 8px;
}

.summary-box {
  display: grid;
  align-items: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 16px 10px;
  font-weight: 600;
  background-color:  #F1F3F9;
  color:#1E1E1E;
  font-size: 14px;
}


.list {
  display: grid;
  font-size: 16px;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  padding: 0 16px;

}

.list-item {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e7eb;
  padding: 0.5rem 0;
}

.list-item:first-child {
  border-top: none;
}

.list-item:nth-child(2) {
  border-top: none;
}

.list-item:nth-child(1) {
  border-top: none;
}

.list-item:nth-child(2) {
  border-top: none;
}

.contact-info {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
}